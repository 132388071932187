<template>
  <div class="container-sys">
    <div id="nav">
      <router-link to="/db">ДБ</router-link> |
      <router-link to="/calc-koef">КОЭФ</router-link> |
      <!--<router-link to="/">Заказы</router-link> |-->
      <router-link to="/settings">Параметры</router-link> |
      <router-link to="/common-orders">Для всех</router-link> |
      <router-link to="/operations">Монитор операций</router-link> |
      <router-link to="/range-work-operation">Загрузка работой</router-link>
    </div>
    <router-view/>
  </div>
</template>

<style>
*,
*::before,
*::after {
  box-sizing: border-box;
}
ul[class],
ol[class] {
  padding: 0;
}
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav { padding: 20px 0px 20px}
#nav a { font-weight: bold; color: #2c3e50;}
#nav a.router-link-exact-active { color: #42b983;}

body{background:#dde7f3}

.container-sys{padding:0px 20px 20px;}

input{padding: 10px 8px;
  outline: none;
  border: 1px solid #b5b5b5;
  border-radius: 4px;
  width: 170px;
}

input[type="checkbox"] {
  width:auto;
}

.btn-def{background: #46b446; cursor:pointer; padding: 10px 10px;
  color: #fff;
  font-size: 14px;
  display:block;
  width: 170px;
  text-align: center;
  border-radius:4px;
  border:1px solid #dde7f3;
}
.btn-def:hover{background: #47d23f }

a{text-decoration:none}

body .dp__action_buttons{width:100%}

</style>
