<template>
    <div class="info-panel">
      <Download :fields="heads" controller="/api/generate-excel" style="float:right" />
      <label>Всего заказов:</label> {{orders.length}} |
    </div>
    <div class="table-row">
      <div class="table-th" style="width:40px; flex:0; display:block">
        <span></span>
      </div>
      <div class="table-th" v-for="head in heads" :key="head.id"
           :class="{'table-th-1': head.flex == 1, 'table-th-1-5': head.flex == 1.5,
           'table-th-2': head.flex == 2}">
          <span v-html="head.name" ></span>
      </div>
    </div>
    <div class="table-row" :class="{
      'green' : order.statusBuff >= 66 && order.statusBuff <= 100 && order.flag_ul === 0,
      'yellow': order.statusBuff >= 33 && order.statusBuff < 66 && order.flag_ul === 0,
      'red': order.statusBuff >= 0 && order.statusBuff < 33 && order.flag_ul === 0,
      'black': order.statusBuff < 0 && order.flag_ul === 0,
      'white': order.flag_ul === 1
      }" v-for="order in orders"
         :key="order.number" @click="getOperationsForOrder(order.id_order_head, order.number)">
      <div class="table-tr" style="width:40px; flex:0; display:block">
        <div class="icon-menu"
             @click.stop="getEventsForOrder(order.id_order_head, order.number)">
            <i class="fa fa-list" aria-hidden="true"></i>
        </div>
      </div>
      <div class="table-tr">
        {{ order.number }}
      </div>
      <div class="table-tr">{{ order.timeMachine }}</div>
      <div class="table-tr">{{ order.timeSubContracting }}</div>
      <div class="table-tr" style="flex:1.5">{{ order.datetime }}</div>
      <div class="table-tr" style="flex:2">{{ order.operation }}</div>
      <div class="table-tr">{{ order.orderStart }}</div>
      <div class="table-tr">{{ order.withPaper }}</div>
      <div class="table-tr">{{ order.lengthBuff }}</div>
      <div class="table-tr" :title="order.titleStatusBuff">{{ order.statusBuff }}</div>
    </div>
    <!-- use the modal component, pass in the prop -->
    <transition name="modal">
      <Modal v-if="showModal" @close="showModal = false">
        <template v-slot:header>
          <h3>{{listOperations.label}}</h3>
        </template>
        <template v-slot:body>
          <div class="custom-table-green">
            <div class="head-wrp " style="display:flex">
              <div class="table-head-green" style="flex:1">Операция</div>
              <div class="table-head-green" style="flex:1">Элемент</div>
              <div class="table-head-green" style="flex:1">Исполнитель</div>
              <div class="table-head-green" style="flex:1">Кол-во</div>
              <div class="table-head-green" style="flex:1">План часы</div>
              <div class="table-head-green" style="flex:1">Состояние</div>
            </div>
            <div v-for="(item, index) in listOperations.items" :key="index" class="table-body-tr">
              <div class="table-body-td" style="flex:1">{{item.operation_name}}</div>
              <div class="table-body-td" style="flex:1"
                   :class="{'error': item.error == 1}" >{{item.detail_name}}</div>
              <div class="table-body-td" style="flex:1">{{item.equip}}</div>
              <div class="table-body-td" style="flex:1">{{item.plan_out_qty}}</div>
              <div class="table-body-td" style="flex:1">{{item.normtime}}</div>
              <div class="table-body-td" style="flex:1">{{item.status}}</div>
            </div>
          </div>
        </template>
      </Modal>
    </transition>

    <transition name="modal">
      <Modal v-if="showModal2" @close="showModal2 = false">
        <template v-slot:header>
          <h3>{{listEvents.label}}</h3>
        </template>
        <template v-slot:body>
          <div class="custom-table-green">
            <div class="head-wrp " style="display:flex">
              <div class="table-head-green" style="flex:1">Событие</div>
              <div class="table-head-green" style="flex:1"></div>
              <div class="table-head-green" style="flex:1">Исполнил</div>
              <div class="table-head-green" style="flex:1">Дата</div>
            </div>
            <div v-for="(item, index) in listEvents.items" :key="index" class="table-body-tr">
              <div class="table-body-td" style="flex:1">{{item.name}}</div>
              <div class="table-body-td" style="flex:1">{{item.check}}</div>
              <div class="table-body-td" style="flex:1">{{item.author}}</div>
              <div class="table-body-td" style="flex:1">{{item.date}}</div>
            </div>
          </div>
        </template>
      </Modal>
    </transition>
</template>

<script>
import axios from 'axios';
import Modal from '../components/help/Modal.vue';
import Download from '../components/download/cross-download.vue';

export default {
  name: 'Home',
  components: {
    Modal, Download,
  },
  data() {
    return {
      counter: 0,
      heads: [],
      orders: [],
      errors: [],
      showModal: false,
      showModal2: false,
      listOperations: {
        label: '',
        items: [],
        loader: false,
      },
      listEvents: {
        label: '',
        items: [],
        loader: false,
      },
    };
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      axios.get('api/orders')
        .then((res) => {
          this.heads = res.data.head;
          this.orders = res.data.orders;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getOperationsForOrder(val, nameOrder) {
      const str = '/api/get-operations?val=';
      this.showModal = true;
      this.listOperations.loader = true;
      axios.get(str + val)
        .then((res) => {
          this.listOperations.label = nameOrder;
          this.listOperations.items = res.data.operations;
          this.listOperations.loader = false;
        })
        .catch((e) => {
          this.listOperations.loader = true;
          this.errors.push(e);
        });
    },
    getEventsForOrder(val, nameOrder) {
      const str = '/api/get-events?val=';
      this.showModal2 = true;
      this.listEvents.loader = true;
      axios.get(str + val)
        .then((res) => {
          this.listEvents.label = nameOrder;
          this.listEvents.items = res.data.events;
          this.listEvents.loader = false;
        })
        .catch((e) => {
          this.listEvents.loader = true;
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
  .table-row{display: flex; flex-direction: row; color:#eee;}

  .table-row:hover .table-tr{opacity: 0.9; cursor:pointer}

  .table-th, .table-tr{flex: 1; text-align:left; padding:10px;}
  .table-th-1-5{flex:1.5}
  .table-th-2{flex:2}

  .table-th{background:#3e5977; font-size:14px; font-weight:bold; border-bottom: 1px solid #777;}
  .table-tr{font-size:14px; border-bottom: 1px solid #777;}

  .table-row.green .table-tr{background: #2e842e; color: #eee;} /* #226f1b; color:#eee;  */
  .table-row.yellow .table-tr{ background: #f2f251; color: #000;} /* #9b8a2e; color:#eee */
  .table-row.red .table-tr{ background: #e33838; color: #eee;} /* #9d3939; color:#eee */
  .table-row.black .table-tr{ background: #14171a; color: #eee;} /* #222a31; color:#eee */

  .table-tr{color:#000}
  /* .odd .table-tr{background:#7191b4;} */

  .container-sys{padding:20px;}

  .table-head-green{background: #caf4b2b3; padding: 6px 12px;}
  .table-body-td{padding: 6px 12px;}
  .table-body-td.error{color:red}
  .table-body-tr{display:flex}
  .table-body-tr:hover{background:#eee}

  .custom-table-green{height: calc(100vh - 120px); overflow-y: auto; padding-right: 15px;}
</style>
