<template>
  <div class="wrp-excel-download">
    <div class="block-select one-line">
      <div @click="toggleFields" class="btn btn-success">
        <span>поля</span>
        <i v-if="flag_open == false"
           class="fa fa-angle-double-down icon-left" aria-hidden="true"></i>
        <i v-else class="fa fa-angle-double-up icon-left" aria-hidden="true"></i>
      </div>
      <div v-if="flag_open == true" class="block-content">
        <div class="wrp-checkbox" v-for="(field,index) in fields" :key="index">
          <input type="checkbox" id="checkbox" v-model="field.check">
          <label for="checkbox">{{field.name}}</label>
        </div>
      </div>
    </div>
    <div class="one-line">
      <a v-if="loader" class="btn btn-def">
        <i class="fa fa-spinner fa-spin fa-1x fa-fw"></i>
        <i class="fa fa-spinner fa-spin fa-1x fa-fw"></i>
        <i class="fa fa-spinner fa-spin fa-1x fa-fw"></i>
      </a>
      <a v-else @click="generateFile" class="btn btn-def">
        <i class="icon-right fa fa-file-excel-o green" aria-hidden="true"></i>
        <span>Выгрузить</span>
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'cross-download',
  props: {
    fields: Array,
    controller: String,
    from: String,
    to: String,
  },
  data() {
    return {
      flag_open: false,
      link: '',
      error: [],
      loader: false,
    };
  },
  methods: {
    toggleFields() {
      if (this.flag_open === false) {
        this.flag_open = true;
      } else {
        this.flag_open = false;
      }
    },
    generateFile() {
      const str = this.controller;
      this.loader = true;
      axios.post(str, { fields: this.fields, from: this.from, to: this.to })
        .then((res) => {
          this.link = document.createElement('a');
          this.link.setAttribute('href', res.data.link);
          this.link.setAttribute('download', res.data.name);
          this.link.click();
          this.loader = false;
        })
        .catch((e) => {
          this.error.push(e);
          this.loader = false;
        });
    },
  },
};
</script>

<style scoped>
  .block-select{position:relative}
  .block-content{background:#fff; padding:12px; display:inline-block;
    position:absolute; right: 0px; top: 24px; z-index:1;}

  .one-line{display:inline-block; margin-left:6px;}
  .icon-right{margin-right:6px;}
  .icon-left{margin-left:6px;}

  .btn{padding:0px 7px; cursor:pointer; display:block;}
  .btn-success{adding: 0px 12px; background: #abdda0;}
  .btn-def{background:#a3c2ff;}

  .wrp-checkbox{white-space:nowrap}

  .fa-file-excel-o{background: #fff; color: #4da64d;}
</style>
