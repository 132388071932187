import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/admin',
    name: 'MergeOperations',
    component: () => import('../views/admin/MergeOperations.vue'),
  },
  {
    path: '/admin/i-sector',
    name: 'InterfaceSector',
    component: () => import('../views/admin/InterfaceSector.vue'),
  },
  {
    path: '/admin/operations',
    name: 'OrderOperations',
    component: () => import('../views/admin/OrderOperations.vue'),
  },
  {
    path: '/common-orders',
    name: 'CommonOrders',
    component: () => import('../views/CommonOrdersTest.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
  },
  {
    path: '/planning',
    name: 'Planning',
    component: () => import('../views/Planing.vue'),
  },
  {
    path: '/range-work-operation',
    name: 'RangeWorkOperation',
    component: () => import('../views/RangeWorkOperation.vue'),
  },
  {
    path: '/db',
    name: 'Db',
    component: () => import('../views/Db.vue'),
  },
  {
    path: '/calc-koef',
    name: 'CalcKoef',
    component: () => import('../views/CalcKoef.vue'),
  },

  {
    path: '/common-orders-test',
    name: 'CommonOrdersTest',
    component: () => import('../views/CommonOrders.vue'),
  },
  {
    path: '/operations-test',
    name: 'Operations-test',
    component: () => import('../views/Operations.vue'),
  },
  {
    path: '/operation-test/:code',
    name: 'OperationDetailTest',
    component: () => import('../views/OperationDetail.vue'),
  },
  {
    path: '/operations',
    name: 'Operations',
    //  component: () => import('../views/OperationsTest.vue'),
    component: () => import('../views/test/OperationsTest.vue'),
  },
  {
    path: '/operation/:code',
    name: 'OperationDetail',
    //  component: () => import('../views/OperationDetailTest.vue'),
    component: () => import('../views/test/OperationDetailTest.vue'),
  },
  {
    path: '/operations-test2',
    name: 'OperationsTest2',
    //  component: () => import('../views/test/OperationsTest.vue'),
    component: () => import('../views/OperationsTest.vue'),
  },
  {
    path: '/operation-test2/:code',
    name: 'OperationDetailTest',
    // component: () => import('../views/test/OperationDetailTest.vue'),
    component: () => import('../views/OperationDetailTest.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // '/orig/'
  routes,
});

export default router;
